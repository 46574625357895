<template>
    <div class="deal_with_box">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>查询</span>
                    <el-input
                        size="small"
                        style="width: 215px"
                        placeholder="角色名称"
                        v-model.trim="name"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="getTableData()"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                </p>
            </div>
        </div>
        <div class="table-title">
            <p>角色管理</p>
            <span>
                <el-button
                    v-if="btnP.add"
                    icon="el-icon-plus"
                    @click="onAdd"
                    class="cancel"
                >
                    新增角色
                </el-button>
            </span>
        </div>
        <Table
            ref="table"
            @totalNum="totalNum"
            @onEdit="onEdit"
            @onDelete="onDelete"
        ></Table>
        <div class="page-box">
            <el-pagination
                v-if="isPage"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 新增角色 -->
        <el-dialog
            :visible.sync="dialogAdd"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="clue_assist"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新增角色
                </div>
            </template>
            <div class="dialog_info">
                <el-form
                    label-position="left"
                    style="margin: 0 auto; width: 70%"
                    size="small"
                    label-width="90px"
                >
                    <el-form-item label="角色名称" required>
                        <el-input
                            v-model="form.name"
                            placeholder="请输入角色名称"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="角色备注">
                        <el-input
                            v-model="form.remark"
                            placeholder="请输入角色备注"
                            type="textarea"
                            rows="3"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    style="margin-right: 16px; background-color: #2370eb"
                    round
                    @click="subAddRole"
                    size="mini"
                    >保 存</el-button
                >
            </div>
        </el-dialog>
        <!-- 删除角色 -->
        <el-dialog
            :visible.sync="dialogDelete"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="clue_assist"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    删除角色
                </div>
            </template>
            <div class="dialog_info">
                <p>
                    <i
                        class="el-icon-warning-outline"
                        style="color: #ff8a00"
                    ></i>
                    确定删除此角色？删除后数据不可恢复
                </p>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    style="margin-right: 16px; background-color: #2370eb"
                    round
                    @click="onSubmitDelete"
                    size="mini"
                    >保 存</el-button
                >
            </div>
        </el-dialog>
        <!-- x修改 -->
        <!-- <el-drawer
            style="border: none; outline: none"
            :visible.sync="drawerEdit"
            :direction="direction"
            :before-close="handleDrawer"
            size="800px"
        >
            <template slot="title">
                <div
                    style="
                        flex: 1;
                        display: flex;
                        align-items: center;
                        color: #101010;
                    "
                >
                    编辑成员
                </div>
            </template>
            <Edit ref="edit" @close="handleDrawer" />
        </el-drawer> -->
    </div>
</template>

<script>
import Table from './table/table.vue';
import Edit from './edit/edit.vue';
import { addRole, deleteRole } from '@/api/admin/role/role.js';
export default {
    data() {
        return {
            currentPage: 1,
            pagesize: 20,
            total: 0,
            name: '',
            direction: 'rtl',
            dialogAdd: false,
            dialogDelete: false,
            drawerEdit: false,
            tableRow: {},
            btnP: {},
            form: {},
            isPage: false,
        };
    },
    components: {
        Table,
        Edit,
    },
    created() {},
    mounted() {
        this.getBtn();
    },
    methods: {
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = btn;
            this.getPageData();
        },
        getPageData() {
            if (
                sessionStorage.getItem('pageData') &&
                JSON.parse(sessionStorage.getItem('pageData'))
            ) {
                this.currentPage = JSON.parse(
                    sessionStorage.getItem('pageData')
                ).pageNum;
                this.pagesize = JSON.parse(
                    sessionStorage.getItem('pageData')
                ).pageSize;
                this.name = JSON.parse(sessionStorage.getItem('pageData')).name;

                sessionStorage.removeItem('pageData');
                this.isPage = true;
            } else {
                this.isPage = true;
            }
            this.getData();
        },
        getTableData() {
            this.currentPage = 1;
            this.getData();
        },
        getData() {
            this.$refs.table.getData(
                this.name,
                this.currentPage,
                this.pagesize,
                this.btnP
            );
        },
        totalNum(row) {
            this.total = row;
        },

        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        onAdd() {
            this.dialogAdd = true;
        },
        // 新增角色
        subAddRole() {
            let data = {
                param: {
                    ...this.form,
                },
            };
            if (!this.form.name) {
                return this.$message.error('请输入角色名称');
            }
            addRole(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleClose();
                }
            });
        },
        onEdit(row) {
            this.$router.push({
                path: '/editRoleaddConfig',
                query: {
                    id: row.id,
                },
            });
        },
        onDelete(row) {
            this.tableRow = row;
            this.dialogDelete = true;
        },
        onSubmitDelete() {
            let data = {
                param: {
                    id: this.tableRow.id,
                    status: 0,
                },
            };
            deleteRole(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleClose();
                }
            });
        },
        handleClose() {
            this.form = {};
            this.dialogAdd = false;
            this.dialogDelete = false;
            this.getData();
        },
        handleDrawer() {
            this.drawerEdit = false;
            this.getData();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.deal_with_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 600;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                .btn1,
                .btn2 {
                    display: inline-block;
                    width: 96px;
                    height: 32px;
                    background: #f0f0f0;
                    box-shadow: 0px 2px 4px 0px rgba(252, 239, 198, 0.6);
                    border-radius: 2px;
                    text-align: center;
                    line-height: 32px;
                    font-weight: normal;
                    cursor: pointer;
                    img {
                        width: 16px;
                        height: 16px;
                        vertical-align: -3px;
                        margin-right: 3px;
                    }
                }
                .btn2.active {
                    background: #f7ece5;
                    margin: 0 8px 0 0;
                }
                .btn1.active {
                    background: #fcefc6;
                }
                button {
                    background: #2370eb;
                    border-radius: 2px;

                    margin-right: 16px;
                }
            }
        }
        .search-two {
            padding-bottom: 10px;
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            flex: 1;
        }

        button {
            font-size: 12px;
            padding: 0 15px;
            height: 26px;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
            background: #fff;
            i {
                margin-right: 6px;
                font-size: 12px;
            }
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Times {
        display: inline-block;
        width: 20px;
        line-height: 16px;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        background: #ff760f;
        border-radius: 63px 63px 63px 1px;
        text-align: center;
    }
    .Timess {
        background: #ff9385;
        margin-left: 3px;
    }
}
.dialog_info {
    width: 85%;
    margin: 25px auto 0;
    padding-bottom: 110px;
    font-size: 14px;
    color: #333333;
    p {
        margin-bottom: 16px;
        font-weight: 600;
    }
    span {
        display: inline-block;
        width: 215px;
        height: 48px;
        background: #f9fafc;
        border-radius: 8px;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
        i {
            color: #2370eb;
        }
    }
    .icon-zhuyi {
        display: block;
        margin: 0 auto 45px;
        font-size: 40px;
        color: #feb516;
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
.item {
    margin-left: 12px;
    margin-top: -2px;
    margin-right: 12px;
}
/deep/ .el-badge__content.is-fixed {
    top: 5px;
    right: 12px;
}
</style>
