<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="10" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="角色名称" show-overflow-tooltip width="135">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.name ? scope.row.name : '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="成员数量" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.userNumber ? scope.row.userNumber : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>

            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="备注" show-overflow-tooltip width="180">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.remark ? scope.row.remark : '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="创建人" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.nickName ? scope.row.nickName : '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="操作" width="130" fixed="right">
                <template slot-scope="scope">
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        @click="onEdit(scope.row)"
                        >编辑</el-button
                    >
                    <el-button
                        style="color: #d0021b"
                        type="text"
                        v-if="scope.row.id > 21"
                        @click="onDelete(scope.row)"
                        >删除</el-button
                    >
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>

<script>
import { roleList, adminMenuList } from '@/api/admin/role/role.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
            tableRow: {},
            dialog: false,
            btnP: {},
            pageData: {},
        };
    },
    components: {},
    created() {},
    methods: {
        getData(name, pageNum, pageSize, btnP) {
            this.btnP = btnP;
            let data = {
                param: {
                    status: 1,
                },
                pageSize: pageSize,
                pageNum: pageNum,
            };
            if (name) {
                data.param.name = name;
            }
            this.pageData = {
                name: name,
                pageNum: pageNum,
                pageSize: pageSize,
            };

            roleList(data).then((res) => {
                if (res.code == 200) {
                    this.$emit('totalNum', res.data.total);
                    this.tableData = res.data.list;
                }
            });
        },
        onEdit(data) {
            sessionStorage.setItem('pageData', JSON.stringify(this.pageData));
            this.$emit('onEdit', data);
        },
        onDelete(data) {
            this.$emit('onDelete', data);
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;
    .icon-ziyuanxhdpi {
        color: #2370eb;
        cursor: pointer;
        vertical-align: -1px;
    }
}

/deep/ .el-table .cell {
    text-overflow: initial;
    padding-right: 0;
}
.dl_con {
    width: 80%;
    margin: 35px auto 100px;
    text-align: center;
    color: #333;
    font-weight: 600;
    font-size: 14px;
    i {
        font-weight: 400;
        font-size: 60px;
        color: #f5a623;
        display: block;
        margin-bottom: 20px;
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
    .el-button--primary {
        background-color: #2370eb;
    }
}
</style>
